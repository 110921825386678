import React from 'react';
import { Link } from 'gatsby';
import ListGroup from 'react-bootstrap/ListGroup'
import Layout from '../components/layout'

const Index = () => {
  return (
    <Layout pageLang="en_US">
      <h1>Tools</h1>
      <p>This is a collection of various tools that may be useful to vocalsynth users. More will be added over time.</p>
      <ListGroup className="my-3 mx-auto col-sm-6">
        <ListGroup.Item action as={Link} to="/vbtougen">UTAU Voicebank Terms of Use Generator</ListGroup.Item>
        <ListGroup.Item action as={Link} to="/akorin">Akorin: Alternative to OREMO</ListGroup.Item>
      </ListGroup>
    </Layout>
  );
}
 
export default Index;